import Splide from "@splidejs/splide";

export function slider() {
	const sliders = [];
	var elms = document.getElementsByClassName("splide");

	for (var i = 0; i < elms.length; i++) {
		sliders.push(new Splide(elms[i]).mount());
	}

	return sliders;
}

Splide.defaults = {
	type: "slide",
	autoplay: false,
	rewind: true,
	arrows: true,
	autoHeight: true
};
