import { preload }	from "./modules/preload";
import { nav } 		from "./modules/nav";
import { slider } 	from './modules/slider';
import { lightbox }	from './modules/lightbox';
import { toggle }	from './modules/toggle';
import { rellax } 	from './modules/modrellax';

window.onload = function () {
	preload();
	nav();
	slider();
	lightbox();
	toggle();
	rellax();
};
