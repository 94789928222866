import GLightbox from 'glightbox'

export function lightbox() {
	GLightbox({
		loop: true,
		touchNavigation: true,
		autoplayVideos: true,
		openEffect: 'fade',
		closeEffect: 'fade',
		slideEffect: 'fade',
		videosWidth: '1440px'
	})
}
